import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Divider,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { amber, blue, green, grey, orange } from "@mui/material/colors";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import Spinner from "../../components/shared/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { learningActions } from "../../store";
import SchoolIcon from "@mui/icons-material/School";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import ReactGA from "react-ga";

import NoteAltIcon from "@mui/icons-material/NoteAlt";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import LightbulbRoundedIcon from "@mui/icons-material/LightbulbRounded";
import LightbulbTwoToneIcon from "@mui/icons-material/LightbulbTwoTone";
import LocalLibraryRoundedIcon from "@mui/icons-material/LocalLibraryRounded";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import DesktopMacTwoToneIcon from "@mui/icons-material/DesktopMacTwoTone";
import LaptopWindowsTwoToneIcon from "@mui/icons-material/LaptopWindowsTwoTone";
import ScreenShareTwoToneIcon from "@mui/icons-material/ScreenShareTwoTone";
import CastForEducationRoundedIcon from "@mui/icons-material/CastForEducationRounded";

const Learning = () => {
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const handleLearningCategorySelect = async (category) => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/posts/getLearningFileStructure/${category}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      dispatch(learningActions.fileStructure(response.data.files));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }

    navigate(`/learning/${category}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <>
      <Spinner open={isLoading} />
      <Box sx={{ py: 6, bgcolor: "secondary.dark" }}>
        <Box
          sx={{
            maxWidth: "750px",
            mx: "auto",
            px: 2,
            textAlign: { xs: "center", sm: "left" },
          }}
        >
          <Typography variant="h4" sx={{ color: grey[100] }}>
            Welcome to Learning Hub
          </Typography>
          <Typography sx={{ mt: 2, fontSize: "1.1rem", color: grey[300] }}>
            Find the learning materials you need from our huge amount of online
            resource collection and make yourself futureproof.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          maxWidth: "1050px",
          mx: "auto",
          mt: 5,
          mb: 4,
          display: "flex",
          flexWrap: "wrap",
          alignItems: { xs: "center", sm: "stretch" },
          justifyContent: { xs: "center", sm: "stretch" },
        }}
      >
        <Card
          sx={{ maxWidth: 310, mx: 2, borderRadius: 2, my: 1 }}
          variant="outlined"
        >
          <CardActionArea onClick={() => handleLearningCategorySelect("dept")}>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
              }}
            >
              <Box>
                <SchoolIcon
                  sx={{
                    fontSize: "2.5rem",
                    mr: { xs: 2, sm: 2 },
                  }}
                  color="info"
                />
              </Box>
              <Box>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  color="info.main"
                >
                  Departmental Jobs
                </Typography>
                <Typography color="text.primary">
                  Departmental government and autonomous company job preparation
                </Typography>
              </Box>
            </CardContent>
          </CardActionArea>
        </Card>

        <Card
          sx={{ maxWidth: 310, mx: 2, borderRadius: 2, my: 1 }}
          variant="outlined"
        >
          <CardActionArea onClick={() => handleLearningCategorySelect("bcs")}>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
              }}
            >
              <Box>
                <LibraryBooksIcon
                  sx={{
                    fontSize: "2.5rem",
                    p: 0.5,
                    mr: { xs: 2, sm: 2 },
                    // ml: { xs: 0, sm: 1 },
                  }}
                  color="warning"
                />
              </Box>
              <Box>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  color="warning.main"
                >
                  BCS, Bank jobs
                </Typography>
                <Typography color="text.primary">
                  BCS, Government banks and other non departmental government
                  job preparation
                </Typography>
              </Box>
            </CardContent>
          </CardActionArea>
        </Card>

        <Card
          sx={{ maxWidth: 310, mx: 2, borderRadius: 2, my: 1 }}
          variant="outlined"
        >
          <CardActionArea
            onClick={() => handleLearningCategorySelect("higherStudy")}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
              }}
            >
              <Box>
                <FlightTakeoffIcon
                  sx={{
                    fontSize: "2.5rem",
                    mr: { xs: 2, sm: 2 },
                  }}
                  color="success"
                />
              </Box>
              <Box>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  color="success.main"
                >
                  Higher Studies
                </Typography>
                <Typography color="text.primary">
                  Prepare for higher studies opportunities at top ranked
                  universities abroad
                </Typography>
              </Box>
            </CardContent>
          </CardActionArea>
        </Card>
      </Box>
      <Box sx={{ maxWidth: 1050, mx: "auto", my: 8, px: 2 }}>
        <Typography
          variant="h1"
          color="text.secondary"
          sx={{ mb: 2, mt: 4, fontSize: "1.6rem", fontWeight: 500 }}
        >
          Class Lectures
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: { xs: "center", sm: "stretch" },
            justifyContent: { xs: "center", sm: "stretch" },
          }}
        >
          <Card
            sx={{ maxWidth: 350, borderRadius: 2, mr: 4, my: 2 }}
            elevation={4}
          >
            <CardActionArea
              component={RouterLink}
              to="https://sites.google.com/view/kueteee/home"
              target="_blank"
            >
              <NoteAltIcon
                sx={{
                  bgcolor: blue[50],
                  color: blue[500],
                  borderRadius: 6,
                  p: 1.4,
                  ml: 2,
                  mt: 3,
                  fontSize: "4rem",
                }}
              />
              <CardContent>
                <Typography
                  gutterBottom
                  component="div"
                  sx={{
                    fontSize: "1.4rem",
                    fontWeight: 500,
                    color: blue[800],
                  }}
                >
                  EEE Class Lecture Archieve
                </Typography>
                <Typography color="text.secondary">
                  Get all class lectures of EEE department at same place
                  organized according to semesters
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Box>
      </Box>

      <Box
        sx={{
          maxWidth: 1050,
          mx: "auto",
          my: 8,
          px: 2,
        }}
      >
        <Typography
          variant="h1"
          color="text.secondary"
          sx={{ mb: 2, mt: 4, fontSize: "1.6rem", fontWeight: 500 }}
        >
          Learning platforms from Kuetians
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: { xs: "center", sm: "stretch" },
            justifyContent: { xs: "center", sm: "stretch" },
          }}
        >
          <Card
            sx={{ maxWidth: 350, borderRadius: 2, mr: 4, my: 2 }}
            elevation={4}
          >
            <CardActionArea
              component={RouterLink}
              to="https://ostad.app/en"
              target="_blank"
            >
              <CastForEducationRoundedIcon
                sx={{
                  bgcolor: orange[50],
                  color: orange[500],
                  borderRadius: 6,
                  p: 1.8,
                  ml: 2,
                  mt: 3,
                  fontSize: "4rem",
                }}
              />
              <CardContent>
                <Typography
                  gutterBottom
                  component="div"
                  sx={{
                    fontSize: "1.4rem",
                    fontWeight: 500,
                    color: orange[800],
                  }}
                >
                  Ostad
                </Typography>
                <Typography color="text.secondary">
                  Skill development platform focusing on live learning having
                  30,000+ users
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card
            sx={{ maxWidth: 350, borderRadius: 2, mr: 4, my: 2 }}
            elevation={4}
          >
            <CardActionArea
              component={RouterLink}
              to="https://www.facebook.com/Exceleschool"
              target="_blank"
            >
              <CastForEducationRoundedIcon
                sx={{
                  bgcolor: green[50],
                  color: green[500],
                  borderRadius: 6,
                  p: 1.8,
                  ml: 2,
                  mt: 3,
                  fontSize: "4rem",
                }}
              />
              <CardContent>
                <Typography
                  gutterBottom
                  component="div"
                  sx={{
                    fontSize: "1.4rem",
                    fontWeight: 500,
                    color: green[800],
                  }}
                >
                  Excel e-School
                </Typography>
                <Typography color="text.secondary">
                  Online learning platform where anyone can learn his/her
                  required skill
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default Learning;
