export const departments = [
  { id: 1, long: "Electrical and Electronic Engineering", short: "EEE" },
  { id: 2, long: "Computer Science & Engineering", short: "CSE" },
  { id: 3, long: "Mechanical Engineering", short: "ME" },
  { id: 4, long: "Civil Engineering", short: "CE" },
  { id: 5, long: "Leather Engineering", short: "LE" },
  { id: 6, long: "Textile Engineering", short: "TE" },
  { id: 7, long: "Energy Science and Engineering", short: "ESE" },
  { id: 8, long: "Chemical Engineering", short: "ChE" },
  { id: 9, long: "Mechatronics Engineering", short: "MTE" },
  { id: 10, long: "Electronics and Communication Engineering", short: "ECE" },
  { id: 11, long: "Biomedical Engineering", short: "BME" },
  { id: 12, long: "Materials Science and Engineering", short: "MSE" },
  {
    id: 13,
    long: "Building Engineering & Construction Management",
    short: "BECM",
  },
  { id: 14, long: "Urban and Regional Planning", short: "URP" },
  { id: 15, long: "Industrial Engineering and Management", short: "IEM" },
  {
    id: 16,
    long: "Architecture",
    short: "ARCH",
  },
];

export const bloodGroupList = [
  "A+",
  "A-",
  "B+",
  "B-",
  "O+",
  "O-",
  "AB+",
  "AB-",
  "N/A",
];

export const batchList = [
  "2025",
  "2024",
  "2023",
  "2022",
  "2021",
  "2020",
  "2019",
  "2018",
  "2017",
  "2016",
  "2015",
  "2014",
  "2013",
  "2012",
  "2011",
  "2010",
  "2009",
  "2008",
  "2007",
  "2006",
  "2005",
  "2004",
  "2003",
  "2002",
  "2001",
  "2000",
  "1999",
  "1998",
  "1997",
  "1996",
  "1995",
  "1994",
  "1993",
  "1992",
  "1991",
  "1990",
  "1989",
  "1988",
  "1987",
  "1986",
  "1985",
  "1984",
  "1983",
  "1982",
  "1981",
  "1980",
  "1979",
  "1978",
  "1977",
  "1976",
  "1975",
  "1974",
  "1973",
  "1972",
  "1971",
];

export const postCategoryList = [
  {
    name: "Job vacancy",
    value: "vacancy",
  },
  {
    name: "Job seeking post",
    value: "jobSeek",
  },
  {
    name: "Viva experience",
    value: "viva",
  },
  {
    name: "Announcement",
    value: "announcement",
  },
  {
    name: "Guidance/Suggestion",
    value: "guidance",
  },
  {
    name: "Information",
    value: "info",
  },
  {
    name: "Knowlwdge sharing",
    value: "knowledgeShare",
  },
  {
    name: "Events/Programs",
    value: "events",
  },
  {
    name: "Other",
    value: "other",
  },
];

export const status = [
  {
    id: 1,
    name: "I am a service holder",
    value: "serviceHolder",
  },
  {
    id: 2,
    name: "I am managing my own businessman",
    value: "businessman",
  },
  {
    id: 4,
    name: "I am doing higher study abroad",
    value: "higherStudy",
  },
  {
    id: 3,
    name: "I am still seeking an opportunity",
    value: "seekingJob",
  },

  {
    id: 5,
    name: "I am a running student of Kuet",
    value: "runningStudent",
  },
];

export const districts = [
  "Bagerhat",
  "Bandarban",
  "Barguna",
  "Barisal",
  "Bhola",
  "Bogra",
  "Brahmanbaria",
  "Chandpur",
  "Chittagong",
  "Chuadanga",
  "Comilla",
  "Coxs Bazar",
  "Dhaka",
  "Dinajpur",
  "Faridpur",
  "Feni",
  "Gaibandha",
  "Gazipur",
  "Gopalganj",
  "Habiganj",
  "Jamalpur",
  "Jessore",
  "Jhalokati",
  "Jhenaidah",
  "Joypurhat",
  "Khagrachhari",
  "Khulna",
  "Kishoreganj",
  "Kurigram",
  "Kushtia",
  "Lakshmipur",
  "Lalmonirhat",
  "Madaripur",
  "Magura",
  "Manikganj",
  "Meherpur",
  "Moulvibazar",
  "Munshiganj",
  "Mymensingh",
  "Naogaon",
  "Narail",
  "Narayanganj",
  "Narsingdi",
  "Natore",
  "Nawabganj",
  "Netrakona",
  "Nilphamari",
  "Noakhali",
  "Pabna",
  "Panchagarh",
  "Patuakhali",
  "Pirojpur",
  "Rajbari",
  "Rajshahi",
  "Rangamati",
  "Rangpur",
  "Satkhira",
  "Shariatpur",
  "Sherpur",
  "Sirajganj",
  "Sunamganj",
  "Sylhet",
  "Tangail",
  "Thakurgaon",
];

export const countries = [
  "Bangladesh",
  "United States of America (USA)",
  "Canada",
  "Finland",
  "Germany",
  "Australia",
  "Japan",
  "United Arab Emirates (UAE)",
  "United kingdom (UK)",
  "Malaysia",
  "Austria",
];

export const countryStates = [
  "Alabama, United States of America (USA)",
  "Alaska, United States of America (USA)",
  "Arizona, United States of America (USA)",
  "Arkansas, United States of America (USA)",
  "California, United States of America (USA)",
  "Colorado, United States of America (USA)",
  "Connecticut, United States of America (USA)",
  "Delaware, United States of America (USA)",
  "Florida, United States of America (USA)",
  "Georgia, United States of America (USA)",
  "Hawaii, United States of America (USA)",
  "Idaho, United States of America (USA)",
  "Illinois, United States of America (USA)",
  "Indiana, United States of America (USA)",
  "Iowa, United States of America (USA)",
  "Kansas, United States of America (USA)",
  "Kentucky, United States of America (USA)",
  "Louisiana, United States of America (USA)",
  "Maine, United States of America (USA)",
  "Maryland, United States of America (USA)",
  "Massachusetts, United States of America (USA)",
  "Michigan, United States of America (USA)",
  "Minnesota, United States of America (USA)",
  "Mississippi, United States of America (USA)",
  "Missouri, United States of America (USA)",
  "Montana, United States of America (USA)",
  "Nebraska, United States of America (USA)",
  "Nevada, United States of America (USA)",
  "New Hampshire, United States of America (USA)",
  "New Jersey, United States of America (USA)",
  "New Mexico, United States of America (USA)",
  "New York, United States of America (USA)",
  "North Carolina, United States of America (USA)",
  "North Dakota, United States of America (USA)",
  "Ohio, United States of America (USA)",
  "Oklahoma, United States of America (USA)",
  "Oregon, United States of America (USA)",
  "Pennsylvania, United States of America (USA)",
  "Rhode Island, United States of America (USA)",
  "South Carolina, United States of America (USA)",
  "South Dakota, United States of America (USA)",
  "Tennessee, United States of America (USA)",
  "Texas, United States of America (USA)",
  "Utah, United States of America (USA)",
  "Vermont, United States of America (USA)",
  "Virginia, United States of America (USA)",
  "Washington, United States of America (USA)",
  "West Virginia, United States of America (USA)",
  "Wisconsin, United States of America (USA)",
  "Wyoming, United States of America (USA)",
  "Alberta, Canada",
  "British Columbia, Canada",
  "Manitoba, Canada",
  "New Brunswick, Canada",
  "Newfoundland and Labrador, Canada",
  "Nova Scotia, Canada",
  "Ontario, Canada",
  "Prince Edward Island, Canada",
  "Quebec, Canada",
  "Saskatchewan, Canada",
  "Central Finland, Finland",
  "CentralOstrobothnia, Finland",
  "Kainuu, Finland",
  "Kanta-Haeme, Finland",
  "Kymenlaakso, Finland",
  "Lapland, Finland",
  "NorthKarelia, Finland",
  "NorthOstrobothnia, Finland",
  "NorthSavo, Finland",
  "Ostrobothnia, Finland",
  "Paijat-Hame, Finland",
  "Pirkanmaa, Finland",
  "Satakunta, Finland",
  "SouthKarelia, Finland",
  "SouthOstrobothnia, Finland",
  "SouthSavo, Finland",
  "SouthwestFinland, Finland",
  "Uusimaa, Finland",
  "Berlin, Germany",
  "Bayern (Bavaria), Germany",
  "Niedersachsen (Lower Saxony), Germany",
  "Baden-Württemberg, Germany",
  "Rheinland-Pfalz (Rhineland-Palatinate), Germany",
  "Sachsen (Saxony), Germany",
  "Thüringen (Thuringia), Germany",
  "Hessen, Germany",
  "Nordrhein-Westfalen (North Rhine-Westphalia), Germany",
  "Sachsen-Anhalt (Saxony-Anhalt), Germany",
  "Brandenburg, Germany",
  "Mecklenburg-Vorpommern, Germany",
  "Hamburg, Germany",
  "Schleswig-Holstein, Germany",
  "Saarland, Germany",
  "Bremen, Germany",
  "New South Wales, Australia",
  "Queensland, Australia",
  "South Australia, Australia",
  "Tasmania, Australia",
  "Victoria, Australia",
  "Western Australia, Australia",
  "Aichi, Japan",
  "Akita, Japan",
  "Aomori, Japan",
  "Chiba, Japan",
  "Ehime, Japan",
  "Fukui, Japan",
  "Fukuoka, Japan",
  "Fukushima, Japan",
  "Gifu, Japan",
  "Gunma, Japan",
  "Hiroshima, Japan",
  "Hokkaido, Japan",
  "Hyōgo, Japan",
  "Ibaraki, Japan",
  "Ishikawa, Japan",
  "Iwate, Japan",
  "Kagawa, Japan",
  "Kagoshima, Japan",
  "Kanagawa, Japan",
  "Kōchi, Japan",
  "Kumamoto, Japan",
  "Kyōto, Japan",
  "Mie, Japan",
  "Miyagi, Japan",
  "Miyazaki, Japan",
  "Nagano, Japan",
  "Nagasaki, Japan",
  "Nara, Japan",
  "Niigata, Japan",
  "Oita, Japan",
  "Okayama, Japan",
  "Okinawa, Japan",
  "Osaka, Japan",
  "Saga, Japan",
  "Saitama, Japan",
  "Shiga, Japan",
  "Shimane, Japan",
  "Shizuoka, Japan",
  "Tochigi, Japan",
  "Tokushima, Japan",
  "Tōkyō, Japan",
  "Tottori, Japan",
  "Toyama, Japan",
  "Wakayama, Japan",
  "Yamagata, Japan",
  "Yamaguchi, Japan",
  "Yamanashi, Japan",
  "Abu Dhabi, United Arab Emirates (UAE) ",
  "Dubai, United Arab Emirates (UAE) ",
  "Sharjah, United Arab Emirates (UAE) ",
  "Ajman, United Arab Emirates (UAE) ",
  "Umm Al Quwain, United Arab Emirates (UAE) ",
  "Ras Al Khaimah, United Arab Emirates (UAE) ",
  "Fujairah, United Arab Emirates (UAE) ",
  "England , United kingdom (UK)",
  "Ireland, United kingdom (UK)",
  "Scotland, United kingdom (UK)",
  "Wales, United kingdom (UK)",
  "Kuala Lumpur, Malaysia",
  "Labuan, Malaysia",
  "Federal Territory of Putrajaya, Malaysia",
  "Johor, Malaysia",
  "Kedah, Malaysia",
  "Kelantan, Malaysia",
  "Malacca, Malaysia",
  "Negeri Sembilan, Malaysia",
  "Pahang, Malaysia",
  "Perak, Malaysia",
  "Perlis, Malaysia",
  "Penang, Malaysia",
  "Sabah, Malaysia",
  "Sarawak, Malaysia",
  "Selangor, Malaysia",
  "Terengganu, Malaysia",
  "Burgenland, Austria",
  "Carinthia (Kärnten), Austria",
  "Lower Austria (Nieder­österreich), Austria",
  "Salzburg, Austria",
  "Styria (Steiermark), Austria",
  "Tyrol(Tirol), Austria",
  "Upper Austria (Ober­österreich), Austria",
  "Vienna(Wien), Austria",
  "Vorarlberg, Austria",
];

export const interests = [
  "software engineering",
  "poduct management",
  "intrumentaion and control",
  "data science",
  "artificial intelligence",
  "machine lerarning",
  "web development",
  "power plant",
  "nuclear engineering",
  "telecommunication",
  "netowrking",
  "PLC",
];
