import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
  Paper,
} from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";
import Spinner from "../../components/shared/Spinner";
import PostCard from "../../components/shared/PostCard";
import HorizontalRuleRoundedIcon from "@mui/icons-material/HorizontalRuleRounded";
import { Link as RouterLink } from "react-router-dom";
import { grey } from "@mui/material/colors";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import DonorCard from "../../components/shared/DonorCard";
import DonationEventCard from "../../components/shared/DonationEventCard";

const Mission = () => {
  const theme = useTheme();
  const matchesSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  const [posts, setPosts] = useState(null);

  const [donors, setDonors] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const auth = useSelector((state) => state.auth);

  const userId = auth ? auth._id : null;

  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box
        sx={{
          py: 4,
          maxWidth: 1020,
          mx: "auto",
        }}
      >
        <Spinner open={isLoading} />
        <Box id="mission">
          <Box sx={{ textAlign: "center", mb: 4, px: 2 }}>
            <HorizontalRuleRoundedIcon sx={{ fontSize: "3rem" }} color="info" />
            <Typography variant="h4" color="grey.100">
              Our Mission
            </Typography>
          </Box>
          <Paper
            elevation={6}
            sx={{
              p: { xs: 2, sm: 4 },
              mx: 2,
              bgcolor: "secondary.main",
              textAlign: "justify",
              color: grey[200],
              borderRadius: 3,
            }}
          >
            <Typography>
              The mission of TRY is to provide support and assistance to
              communities in need, both within KUET and beyond, through
              non-professional volunteer efforts.The missions of TRY are
              enormous. These include activities such as collecting funds for
              diseased or ailing KUETians or non-Kuetians.Providing scholarships
              and educational content to the students who are talented but
              financially unstable.Providing winter clothes to the needy people
              who are living in the coldest area.Arrange the Iftar event during
              Ramadan and provide Iftar items and Eid gifts for necessary
              people.collecting funds for the Zakat event to make some people
              self-reliant as they are disabled or helpless.The mission of TRY
              also includes serving the flood-affected people.The missions of
              TRY are increasing, and they are trying to best serve the
              communities.
            </Typography>
          </Paper>
        </Box>
        <Box sx={{ my: 6 }}>
          <Box sx={{ textAlign: "center", mb: 4, px: 2 }}>
            <HorizontalRuleRoundedIcon sx={{ fontSize: "3rem" }} color="info" />
            <Typography variant="h4" color="grey.100">
              Our Vision
            </Typography>
          </Box>
          <Paper
            elevation={6}
            sx={{
              p: { xs: 2, sm: 4 },
              mx: 2,
              bgcolor: "secondary.main",
              textAlign: "justify",
              color: grey[200],
              borderRadius: 3,
            }}
          >
            <Typography>
              The vision of TRY is to spread love and happiness among people by
              creating a society where people stand by each other in times of
              need. TRY aims to build a supportive community where individuals
              are empowered through social support.The vision also includes
              fostering a culture of sympathy, generosity, and kindness among
              Kuetians and beyond, with the ultimate goal of bringing smiles to
              people's faces and making a positive impact on humanity.
            </Typography>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

export default Mission;
