/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";
import Spinner from "../shared/Spinner";
import PostCard from "../shared/PostCard";

import { Link as RouterLink } from "react-router-dom";
import { grey } from "@mui/material/colors";
import DonationEventCard from "../shared/DonationEventCard";

import HorizontalRuleRoundedIcon from "@mui/icons-material/HorizontalRuleRounded";

const Try = () => {
  const [posts, setPosts] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const auth = useSelector((state) => state.auth);

  const userId = auth ? auth._id : null;

  useEffect(() => {
    if (userId) getPosts();
  }, [userId]);

  const getPosts = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/try/donation?limit=20`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      setPosts(response.data.donations);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ pt: 4, pb: 6 }}>
      <Spinner open={isLoading} />
      <Box sx={{ textAlign: "center", mb: 4, px: 2 }}>
        <HorizontalRuleRoundedIcon sx={{ fontSize: "3rem" }} color="success" />
        <Typography variant="h4">TRY</Typography>
        <Typography sx={{ my: 1 }}>
          Officially reveal at the starting of 2014 in an attempt to do
          something for the people
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "stretch",
        }}
      >
        {posts &&
          posts
            .filter((post) => post.isActive)
            .slice(0, 4)
            .map((post) => <DonationEventCard post={post} />)}
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          variant="outlined"
          color="success"
          component={RouterLink}
          to="/try"
          sx={{ fontSize: "1.1rem", px: 4, py: 1, mt: 4, borderRadius: 6 }}
        >
          Explore more about TRY
        </Button>
      </Box>
    </Box>
  );
};

export default Try;
