import React from "react";

import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
const Contact = () => {
  return (
    <Box sx={{ my: { xs: 4, sm: 8 }, mx: 2 }}>
      <Paper
        elevation={0}
        sx={{
          bgcolor: grey[100],
          maxWidth: 600,
          mx: "auto",
          px: { xs: 2, sm: 8 },
          py: { xs: 2, sm: 4 },
          borderRadius: 4,
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "1.7rem",
            color: "primary.dark",
            textAlign: "center",
          }}
        >
          Contact with TRY
        </Typography>
        <List sx={{ mt: 3 }}>
          <ListItem
            alignItems="flex-start"
            sx={{
              border: "1px solid",
              borderColor: "primary.light",
              borderRadius: 2,
            }}
          >
            <ListItemAvatar sx={{ mt: 1.4 }}>
              <Avatar sx={{ bgcolor: "primary.light" }}>
                <PhoneRoundedIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography
                  sx={{ fontWeight: 500, fontSize: "1.1rem", mb: 0.5 }}
                >
                  TRY Volunteer Team
                </Typography>
              }
              secondary={
                <>
                  {/* <Typography color="text.primary">Batch: 2019</Typography> */}
                  <Typography color="text.primary" sx={{ fontSize: "1rem" }}>
                    Contact: 017-920-15332
                  </Typography>
                </>
              }
            />
          </ListItem>
        </List>
      </Paper>
    </Box>
  );
};

export default Contact;
