import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";
import Spinner from "../../components/shared/Spinner";
import PostCard from "../../components/shared/PostCard";
import HorizontalRuleRoundedIcon from "@mui/icons-material/HorizontalRuleRounded";
import { Link as RouterLink } from "react-router-dom";
import { grey } from "@mui/material/colors";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import DonorCard from "../../components/shared/DonorCard";
import DonationEventCard from "../../components/shared/DonationEventCard";

const AskingDonations = () => {
  return (
    <Box>
      <Box sx={{ mt: 6, mb: 8 }}>
        <Box sx={{ textAlign: "center", mb: 4, px: 2 }}>
          <HorizontalRuleRoundedIcon
            sx={{ fontSize: "3rem" }}
            color="warning"
          />
          <Typography variant="h4" color="text.primary">
            Asking for Donation
          </Typography>
          <Typography sx={{ my: 1 }}>
            Are you looking for a donation? Click the link below and fillup the
            form. TRY volunteer team will reach you out.
          </Typography>
          <Button
            href="https://docs.google.com/forms/d/19S4zcNqIIW0KUNPS8yKf1OhM42CPExU8avHR1SEuciw/edit"
            target="_blank"
            variant="contained"
            color="success"
            sx={{ mt: 4, borderRadius: 10, px: 6, py: 1.5, fontSize: "1.2rem" }}
          >
            Ask for donation
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AskingDonations;
