export const clubs = [
  {
    id: 1,
    title: "Try - Voluntary Organization",
    description: "A Non-Profit Voluntary Organization of KUETians",
    imageUrl: "try.jpeg",
  },
  {
    id: 15,
    title: "Kuet Debating Society",
    description:
      "KDS is the student’s organization of debating society in KUET",
    imageUrl: "KDS.png",
  },
  {
    id: 4,
    title: "KUET Film Society",
    description:
      "A platform for movie lovers to create the trend of watching and sharing films",
    imageUrl: "kfs.jpeg",
  },
  {
    id: 5,
    title: "KUET Photographic Society ",
    description:
      "It is a platform for Photography enthusiastic students, alumni and faculties of KUET",
    imageUrl: "kps.jpeg",
  },
  {
    id: 6,
    title: "KUET Career Club",
    description:
      "A career oriented club of KUET, established  to help KUETians become more career conscious, prepare them as strong competitors for different competitive career opportunities",
    imageUrl: "kcc.jpeg",
  },
  {
    id: 11,
    title: "প্রতিধ্বনি - Musical Association",
    description:
      "Musical Association of KUET. A platform for music lovers in KUET",
    imageUrl: "protiddhoni.jpeg",
  },
  {
    id: 2,
    title: "Dream - Blood Donation Society",
    description:
      "Dream have a vision for the world where no one will die in need of blood",
    imageUrl: "dream.jpeg",
  },
  {
    id: 10,
    title: "কুয়েট থিয়েটার ",
    description:
      '""মৃতের মননশীল মুক্তি"" এই স্লোগানে এগিয়ে যাওয়া ""কুয়েট থিয়েটার""',
    imageUrl: "",
  },
  {
    id: 3,
    title: "Organisation of KUET sports",
    description: "An Organization for Sports lovers of KUET",
    imageUrl: "oks.jpeg",
  },
  {
    id: 7,
    title: "KUET Business and Entrepreneurship Club",
    description:
      "KUET Business & Entrepreneurship Club intends to stash the prospect of business and entrepreneurship",
    imageUrl: "kbec.jpeg",
  },
  {
    id: 8,
    title: "Spectrum",
    description: "A Professional skill development club of KUET",
    imageUrl: "spectrum.jpeg",
  },
  {
    id: 9,
    title: "Dhrupodi",
    description:
      "Dancers' Association of KUET. Dance is an ancient social and cultural aesthetics",
    imageUrl: "dhrupodi.jpeg",
  },
  {
    id: 12,
    title: "KUET Adventure Club",
    description: "A club for Adventure lovers in KUET",
    imageUrl: "kac.jpeg",
  },
  {
    id: 13,
    title: "KUET Artists' Society - কল্পপট",
    description: "A platform for Artists of KUET",
    imageUrl: "kolpopot.jpeg",
  },
  {
    id: 14,
    title: "KUET Radio",
    description: "KUET Radio, the official radio of KUET",
    imageUrl: "radio.jpeg",
  },
  {
    id: 16,
    title: "Cyber Gaming Club of KUET (Cyborg)",
    description: "KUET CYBORG is simply the home of all KUETian gamers",
    imageUrl: "cyborg.jpeg",
  },
  {
    id: 17,
    title: "KUET Math Club",
    description: "Engineers are the magicians of the mathematics",
    imageUrl: "",
  },
  { id: 18, title: "অ-আবৃতি", description: "", imageUrl: "abriti.jpeg" },
];
